@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

.page-enter {
  transform: rotateY(90deg);
  opacity: 0;
}

.page-enter-active {
  transform: rotateY(0deg);
  opacity: 1;
  transition: transform 600ms, opacity 600ms;
}

.page-exit {
  transform: rotateY(0deg);
  opacity: 1;
}

.page-exit-active {
  transform: rotateY(-90deg);
  opacity: 0;
  transition: transform 600ms, opacity 600ms;
}


::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}